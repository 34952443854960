import { USER_ROLES } from 'constants/userRoles'
import { TTaxonomy } from 'services/dictionary/types'
import { TSiteNameRs } from 'store/filters/orders/types'
import { FilterWithId } from 'store/flags/types'
import { TBffPracticeAllResponse } from 'store/practice/types'
import { TPageable } from 'types/general'
import { components } from 'types/schemas'

/* Paperwork types */
export enum PAPERWORK_STATUS {
  ACTIVE = 'ACTIVE',
  RESOLVED = 'RESOLVED',
}

type TBffPaperworkPageDataRs = components['schemas']['BffPaperworkPageDataRs']

export type TBffPaperWorkGridRs = Omit<
  components['schemas']['BffPaperWorkGridRs'],
  'status'
> & {
  status?: 'ACTIVE' | 'RESOLVED'
}

export type TPaperworkPageDataRs = Omit<TBffPaperworkPageDataRs, 'site'> & {
  site?: TSiteNameRs
}

export type TBffPaperWorksPageRs = Omit<
  components['schemas']['BffPaperWorksPageRs'],
  'content'
> & {
  content: TBffPaperWorkGridRs[]
}

export type TPaperworkRs = components['schemas']['BffPaperworkRs']

export type TBffPaperworkRs = Omit<
  components['schemas']['BffPaperworkRs'],
  'pageDataList'
> & { pageDataList: TPaperworkPageDataRs[] }

export type TBffPaperWorksFilter =
  components['schemas']['BffPaperWorksFilterRq']

export type TPaperWorksTableFilter = Omit<
  TBffPaperWorksFilter,
  'siteIds' | 'status' | 'faxNumber' | 'receiveDateFrom' | 'receiveDateTo'
> & {
  siteIds: FilterWithId<TSiteNameRs>[]
  status: PAPERWORK_STATUS
  search?: string
  receiveDateFrom: string | null
  receiveDateTo: string | null
}

/* Physicians types */
export type TPhysiciansPageResponse =
  components['schemas']['BffPhysiciansPageRs']

export type TPhysicianGridFilter =
  components['schemas']['PhysicianGridFilterRq']

export type TPhysiciansTableFilter = Omit<
  TPhysicianGridFilter,
  | 'taxonomyIds'
  | 'practiceIds'
  | 'siteIds'
  | 'isPaged'
  | 'search'
  | 'providerIds'
> & {
  taxonomyIds: Array<TTaxonomy>
  siteIds: Array<FilterWithId<TSiteNameRs>>
  practiceIds: Array<FilterWithId<TBffPracticeAllResponse>>
  search?: string
}

/* Users types */
export type TBffPageUserRs = components['schemas']['BffUserPageRs']

export type TBffUserRs = components['schemas']['BffUserGridRs']

export type TUserFilteringRq = components['schemas']['UserFilteringRq']

type TCompanyFilter = Required<components['schemas']['BffCompanySitesRs']>

export type TUserTableFilter = Omit<
  TUserFilteringRq,
  'siteIds' | 'practiceIds' | 'roles' | 'companyIds'
> & {
  roles: Array<{
    id: USER_ROLES
    label: string
  }>
  siteIds: Array<TSiteNameRs>
  companyIds: Array<TCompanyFilter>
  practiceIds: Array<TBffPracticeAllResponse>
}

/* Procedures types */
export type TBffProcedureGridRs = Omit<
  components['schemas']['BffProcedureGridRs'],
  'id'
> & { id: number }

export type TBffProcedurePageRs = Omit<
  components['schemas']['BffProcedurePageRs'],
  'content'
> & {
  content: TBffProcedureGridRs[]
}

export type TSiteDataResponse = components['schemas']['SiteRs']

export type TBffCheckNeedUpdateRs =
  components['schemas']['BffCheckNeedUpdateRs']

export type TPatientData = components['schemas']['PatientGridRs']

export type TGetPatientsListResponse =
  components['schemas']['PatientGridPageRs']

export type TGetPatientsListFiltersData =
  components['schemas']['PatientGridFilterRq']

export type TGetPatientsListFilters = TGetPatientsListFiltersData & TPageable

export type TGetPatientListParams = Partial<
  Omit<TGetPatientsListFilters, 'searchType' | 'companyIds' | 'genders'>
> & {
  searchType: SEARCH_TYPE
  genders?: string
  companyIds?: string
}

export enum SEARCH_TYPE {
  ORDER_VIEW = 'ORDER_VIEW',
  PATIENT_LIST = 'PATIENT_LIST',
  PATIENT_MERGE = 'PATIENT_MERGE',
}
