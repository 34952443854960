import { TServiceError } from 'utils/api/types'
import { LOADING_STATE, TPageable } from 'types/general'
import { components } from 'types/schemas'

type TPatientGridFilterRq = Omit<
  Partial<components['schemas']['PatientGridFilterRq']>,
  'companyIds'
> & {
  companyIds: number | undefined
}

export type TPatientSearchRequest = TPatientGridFilterRq & TPageable

export type TPatientRequest = Omit<
  components['schemas']['PatientUpdateRq'],
  'ssn' | 'sourceMrn'
> & {
  ssn?: string | null
  sourceMrn?: string | null
}

export type TPatientSearchResponse = Omit<
  Required<components['schemas']['PatientGridPageRs']>,
  'content'
> & {
  content: TPatientGridRs[]
}

export type TPatientResponse = Omit<
  Required<components['schemas']['PatientRs']>,
  'ssn' | 'sourceMrn'
> & {
  ssn: string | null
  sourceMrn?: string | null
}

export type TPatientGridRs = Omit<
  Required<components['schemas']['PatientGridRs']>,
  'ssn' | 'sourceMrn'
> & {
  ssn: string | null
  sourceMrn?: string | null
}

export type TDicomPatient = Partial<
  Omit<TPatientGridRs, 'companyId' | 'id' | 'ssn'>
>

export type TPatientCardRs = components['schemas']['PatientCardRs']

export type TPatientBillingInfoRs =
  components['schemas']['PatientBillingInfoRs']

export type TBffPatientComparisonRs =
  components['schemas']['BffPatientComparisonRs']

export type TBffPatientComparisonCardRs =
  components['schemas']['BffPatientComparisonCardRs']

export type TPatientComparisonFilterRq =
  components['schemas']['PatientComparisonFilterRq']

export type TPatient = components['schemas']['PatientInfoRs']

export type TPatientBatchUpdateRq = Omit<
  components['schemas']['PatientBatchUpdateRq'],
  'ssn' | 'sourceMRN'
> & {
  sourceMRN?: string | null
  ssn?: string | null
}

export type TBffOrdersComparisonPageRs =
  components['schemas']['BffOrdersComparisonPageRs']

type TOrderListInfo = {
  loadingState: LOADING_STATE
  error?: TServiceError | null
  data?: TBffOrdersComparisonPageRs
  pageable: TPageable
}

export type TComparisonInfoResponse = components['schemas']['ComparisonInitRs']
export enum MULTIPLE_TYPE_ENUM {
  TARGET = 'TARGET',
  SOURCE = 'SOURCE',
}

export type TCompareMultipleInfo = {
  resourceId: string
  duplicateIds: string[]
  duplicateIdsPosition: MULTIPLE_TYPE_ENUM
}

export interface IState {
  patientInfo?: TPatient
  loadingState: LOADING_STATE
  error?: TServiceError | null
  compareInfo: {
    loadingState: LOADING_STATE
    multipleInfo: TCompareMultipleInfo
    info?: TBffPatientComparisonRs
    ordersList: Record<string, Partial<TOrderListInfo>>
  }
}

export enum MATCH_TYPE_ENUM {
  AUTO_MATCH = 'AUTO_MATCH',
  POSSIBLE_MATCH = 'POSSIBLE_MATCH',
  MANUAL_MATCH = 'MANUAL_MATCH',
  POSSIBLE_DUPLICATE = 'POSSIBLE_DUPLICATE',
  NO_MATCH = 'NO_MATCH',
}

export enum LINK_UPDATE_ENUM {
  MERGE = 'MERGE',
  NO_MATCH = 'NO_MATCH',
}
