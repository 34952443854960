/* eslint-disable */
// eslint wants to use single quotes for strings that contain single-quotes
// but prettier forces double quotes

export const RISOrders = {
  //common
  ordersRISOrders: 'RIS-Orders',
  ordersCreateRISOrder: 'Create RIS-Order',
  orderCreationDisabled:
    'The ability to create new orders has been disabled for your organization due to non payment. Historical orders and results are still accessible. Please contact Proscan @ 513-924-5003 or <mailToLink>PRSBilling@proscan.com</mailToLink> to make payment and re-enable order creation. Thank you.',
  sourceMRN: 'Source MRN',
  shareRisOrder: 'Share RIS-Order {{accessionNumber}}',
  shareForIndividualDescription: 'Enter email below to share Primary Study',
  ordersEditRISOrder: 'Edit RIS-Order {{accessionNumber}}',
  uploadFilesToRISOrder: 'Upload files to RIS-Order {{accessionNumber}}',
  orderValidationCompleted:
    '{{validationPercentage}} of RIS-Order is completed!',
  orderFillFormForSubmission:
    'Fill out all required info to enable Submit button',
  ordersRISOrderWithAccessionNumber: 'RIS-Order {{accessionNumber}}',
  orderEditRISOrderViewBilling: 'View Billing',
  orderEditRISOrderViewReport: 'View Report',
  ordersPatientFirstName: 'First name',
  ordersPatientLastName: 'Last name',
  insurancePatientMiddleName: 'Middle name',
  address1: 'Address 1',
  address2: 'Address 2',
  city: 'City',
  country: 'Country',
  state: 'State',
  postalCode: 'Postal code',
  phoneNumber: 'Phone number',
  patientsPhoneNumbers: 'Patient’s Phone Numbers',
  ordersPatientSSN: 'Patient SSN',
  guarantorSSN: 'Guarantor SSN',
  policyHolderSSN: 'Policy Holder SSN',
  ordersPatientSSNHelperText: 'Please enter 9 digits from SSN.',
  invalidFormat: 'Invalid format',
  ordersPatientGenderAtBirth: 'Gender at Birth',
  ordersPatientDateOfBirth: 'Date of Birth',
  ordersDetachPatient: 'Detach patient from the order',
  orderPatientSeeDetails: 'See details',
  fieldNameDoesntMatchDicomData:
    '{{fieldLabel}} doesn’t match {{ studyTypes }} study',
  reasonTitle: '{{status}} for {{reason}}',
  inconsistentDICOMAndHLSevenStudies: 'DICOM and HL7',
  inconsistentDICOMStudy: 'DICOM',
  inconsistentHLSevenStudy: 'HL7',
  inconsistentPatientInfoModalTitle: 'Inconsistent info',
  inconsistentPatientInfoModalDescriptionDispatchSourcePatientCard:
    "The selected patients information does not match the DICOM study or HL7 message information. Please correct the patient's data and proceed with the selected patient record",
  inconsistentPatientInfoModalDescriptionDispatchSourceSubmit:
    "The selected patients information does not match the DICOM study or HL7 message information. Please correct the patient's data or click the checkbox and proceed with the selected patient record",
  inconsistentPatientInfoModalCheckboxLabel:
    "I am aware that the selected patient's information does not match the DICOM study information and would like to proceed with the selected patient. I acknowledged that data inconsistencies may lead to future integration issues and will have an affect on the order workflow",
  inconsistentPatientTableDICOMColumnTitle: 'DICOM',
  inconsistentPatientTablePatientCardColumnTitle: 'Patient Card',
  inconsistentPatientTableErrorMessage:
    'Please, click the checkbox to proceed without changes',
  inconsistentPatientDataTooltip: 'Patient data is inconsistent',
  declineRisOrderMenuItem: 'Decline RIS-Order',
  putOnHoldMenuItem: 'Put On Hold',
  onHoldDetailsMenuItem: 'On Hold Details',
  putOnHoldModalWindowTitle: 'Put RIS-Order {{accessionNumber}} On Hold',
  risOrderOnHoldDetailsModalWindowTitle:
    'RIS-Order {{accessionNumber}} On Hold Details',
  putOnHoldModalWindowTitleDescription:
    'Please select at least one reason for On Hold Status',
  cancelAddendum: 'Cancel Addendum',
  cancelAddendumReason: 'Addendum Cancelation Reason',
  onHold: 'On Hold',
  onHoldDetailsFieldLabel: 'On Hold Details',
  onHoldStatusCheckError:
    'At list one reason is required to keep RIS-Order on hold.',
  addNewPatient: 'Add new patient',
  createPatientTitle: 'Create New Patient',
  patientDetailsTitle: 'Patient Details',
  removeCurrentPatientToCreateANewOne:
    'Remove current patient to create a new one',
  billingRelationshipToPatientLabel: 'Relationship to Guarantor',
  billingRelationshipToPolicyHolderLabel: 'Relationship to Policy Holder',
  guarantorLabel: 'Guarantor',
  self: 'Self',
  other: 'Other',
  guarantorRelation: "Patient's relationship to Guarantor",
  billingOtherPersonLabel: 'Other person',
  billingUsePatientAddressLabel: 'Use Patient address',
  billingFirstNameLabel: 'First Name',
  billingMiddleNameLabel: 'Middle Name',
  billingLastNameLabel: 'Last Name',
  billingDateOfBirthLabel: 'Date of Birth',
  billingGenderAtBirthLabel: 'Gender at Birth',
  billingAddressFirstLabel: 'Address',
  billingAddressSecondLabel: 'Address 2',
  billingCityLabel: 'City',
  billingCountryLabel: 'Country',
  billingStateLabel: 'State',
  billingPostalCodeLabel: 'Postal Code',
  billingPatientPhoneNumbers: 'Patient Phone Numbers',
  billingGuarantorPhoneNumbers: 'Guarantor’s Phone Numbers *',
  refMdTitle: 'Ordering Provider',
  reportQAReasonTitle: 'Report QA Reason',
  assignedReaderTitle: 'Assigned Reader',
  urgencyTitle: 'Urgency',
  followUpDateTitle: 'Follow up date',
  followUpTimeTitle: 'Follow up time',
  procedureTitle: 'Procedure',
  billingTypeTitle: 'Billing Type',
  billingStatusTitle: 'Billing Status',
  billingInsuranceGroupName: 'Insurance group',
  dateOfServiceTitle: 'Date of service',
  dateAndTimeOfServiceTitle: 'Date and Time of Service',
  siteNameTitle: 'Site',
  patientTitle: 'Patient',
  statusTitle: 'Status',
  activeStatusesTitle: 'Active Statuses',
  inactiveStatusTitle: 'Inactive Statuses',
  inactiveStatusTooltipTitle: 'Include inactive statuses to the table',
  inactiveStatusLabel: 'Include',
  assignmentTitle: 'Assignment',
  assignmentAlert:
    'At least one order is required to be chosen to enable assignment.',
  generalInfo: 'General info',
  noInfoEntered: 'No info entered',
  referringProviders: 'Referring Providers',
  locationPlaceOfService: 'Location/Place of Service',
  reasonForExamClinicalHistory: 'Reason for Exam / Clinical History',
  noProcedure: 'No Procedure',
  ICD10Code: 'ICD-10 Code',
  phone: 'Phone',
  fluoroTimeMeasure: 'min',
  noTagsAdded: 'No Tags added',
  studyDownloadingNotification:
    'Download in progress. Please wait for the process to complete',
  orderIsBeingEditedBy: 'Order is being edited by:',
  reportIsBeingEditedBy: 'Report is being edited by:',
  billingIsBeingEditedBy: 'Billing information is being edited by:',
  startedCapture: 'started {{dateTime}}',
  startedReportQaCapture: 'started {{date}} at {{time}}',
  secondaryBillingType: 'Secondary Billing type',
  billingRelationshipLabel: 'Relationship',
  billingGuarantorPhoneNumber: 'Guarantor’s Phone Number',
  billingPrimaryPolicyHolderLabel: "Policy Holder's Info (Primary insurance)",
  billingSecondaryPolicyHolderLabel:
    "Policy Holder's Info (Secondary insurance)",

  //tables
  addNewOrder: 'Add New RIS-Order',
  allTabLabel: 'all',
  preparationTabLabel: 'Preparation',
  assignmentTableDescription:
    'Assignment pool contains orders in Waiting for Assignment status that have not been assigned to a radiologist yet',
  selectedOrders: '{{count}} selected',
  reportQATabLabel: 'Report QA',
  billingTabLabel: 'Billing',
  incomingFaxes: 'Incoming faxes',
  proScanAccessionColumnTitle: 'ProScan Accession',
  dateOfBirthTitle: 'Date of Birth',
  creationDateColumnTitle: 'Create Date',
  patientColumnTitle: 'Patient',
  siteColumnTitle: 'Site',
  onHoldReasonsColumnTitle: 'On Hold Reasons',
  tagsColumnTitle: 'Tags',
  andMore: 'and {{n}} more',
  sourceAccessionNumberColumnTitle: 'Source Accession',
  proScanMRNColumnTitle: 'ProScan MRN',
  risOrderStatusColumnTitle: 'RIS-Order Status',
  csvReportGeneration: 'CSV Report is being generated now. Please wait.',
  csvReportCouldNotBeGenerated:
    'CSV Report could not be generated. Please try again later',
  requestedExportExceedLimitations:
    'Requested export exceeds the system limitations. Please narrow your search criteria or contact ProScan Support at <linkText>{{email}}</linkText>',
  procedureExtractedFromDICOM: 'Extracted from DICOM file. No procedure link',
  sourceMrnExtractedFromDICOM: 'Extracted from DICOM file. No source mrn link',
  dateOfBirthExtractedFromDICOM:
    'Extracted from DICOM file. No date of birth link',
  patientExtractedFromDICOM:
    'Extracted from DICOM file. No patient record link',

  //filters
  patientDayOfBirthTitle: 'Patient Date of Birth',
  CPTCodeLabel: 'CPT Code',
  holdReasonTitle: 'On Hold Reason',
  reportReview: 'Report Review',
  hideInReview: 'Hide in review',
  companyTitle: 'Company',
  siteStatesTitle: 'Site state',
  bodyPartLabel: 'Body Part',
  modalityLabel: 'Modality',
  examDescriptionLabel: 'Exam Description',
  searchPlaceholder: 'Search by ProScan Accession or Patient Name',
  hasAssignedRadiologistCheckboxLabel: 'Only assigned for tomorrow',
  assignedRadiologistLabel: 'Assigned radiologist',
  dicomIsNotPossibleToBeDownloaded:
    'This file will be possible to download only after all the changes are saved.',
  reportDeliveryStatus: 'Report Delivery Status',

  //search
  patientsName: "Patient's name",
  dateOfBirth: 'Date of Birth',
  patientsFound_one: '{{count}} patient found',
  patientsFound_other: '{{count}} patients found',
  gender: 'Gender',
  name: 'Name',

  //FlagPopover
  flagPopoverTitle: 'Flags',
  flagPopoverToDo: 'To do',
  flagPopoverPrevious: 'Previous',
  flagPopoverDone: 'Done',
  flagPopoverMarkAllAsDone: 'Mark all as done',
  flagPopoverNoFlags: 'No flags were created for this RIS-Order',
  flagPopoverCompletedBy:
    'Completed by {{firstName}} {{lastName}}, {{completedAt}}',
  completeAllFlagsError: 'Please, complete all flag actions',

  //Paperwork
  paperworkHeader: 'Paperwork',
  paperworkAttachDocument: 'Attach Document',
  paperworkAttachment: 'Attachment',
  paperworkAttached: 'Attached',
  paperworkFormIgnorePage: 'Ignore this page',
  paperworkFormSite: 'Site',
  paperworkFormRisOrder: 'RIS-Order',
  paperworkFormDocumentType: 'Type of document',
  paperworkDownloadPage: 'Download Page',
  paperworkDownloadDocument: 'Download Document',
  openNextFaxAfterSave: 'Open the next fax after save',
  paperworkFilterResolvedOnly: 'Resolved only',
  paperworkTableSearch: 'Search by fax number',
  paperworkTableFaxNumber: 'Fax Number',
  paperworkTableSiteName: 'Site',
  paperworkTableStatus: 'Status',
  paperworkTableReceiveDate: 'Receive Date',
  paperworkTableAttachedTo: 'Attached To',
  active: 'Active',
  resolved: 'Resolved',
  groupAll: 'Select all pages',
  selectRemainingPages: 'Select remaining pages',
  selectedRemainingPages_one: '{{count}} page',
  selectedRemainingPages_other: '{{count}} pages',
  setCriteria: 'Set criteria',
  groupAttachmentHeader_one: 'Set criteria ({{count}} page)',
  groupAttachmentHeader_other: 'Set criteria ({{count}} pages)',

  //Exam
  examPhysicianInfo: 'Physician info',
  referringProviderTitle: 'Referring Provider *',
  searchReferringProvider: 'Search referring provider by name or NPI',
  examPhysicianInformationLabel: 'Physician Information',
  examPhysicianInformationOnlyOneOrderingProvider:
    'There should be only one ordering provider',
  examOrderingProvider: 'Ordering provider',
  examDetails: 'Exam Details',
  examAddNewProvider: 'Add New Provider',
  examInformation: 'Exam Information',
  examInfo: 'Exam Info',
  examPaperwork: 'Exam Paperwork',
  chooseExamPaperworkType: 'Choose the type of uploaded paperwork',
  examProcedure: 'Procedure',
  examTechNoteTitle: 'Tech Note',
  examUrgency: 'Urgency',
  examSourceAccessionNumber: 'Source Accession Number',
  examPhoneNumberRefMD: 'Phone Number (Ref MD)',
  examPointOfServiceLabel: 'Points of service',
  examAdmissionDateLabel: 'Admission date',
  examDateOfServiceTitle: 'Date of service',
  examTimeOfServiceTitle: 'Time of service',
  examImagesAcquiredInTitle: 'Images acquired in',
  examStateTitle: 'State',
  examReasonForExamTitle: 'Reason for Exam / Clinical History',
  examFollowUpDateTitle: 'Follow up date',
  examFollowUpTimeTitle: 'Follow up time',
  examCoachedForMotionTitle: 'Coached for motion',
  examGelMarkerPlacedTitle: 'Gel marker placed',
  examATTNTitle: 'ATTN',
  multipleStudyTitle: 'Multiple study',
  examAttentionTitle: 'Attention',
  examAttentionDescription: 'Max 50 characters',
  examDocumentsForInterpretation: 'Documents for Interpretation',
  examDocumentsForInterpretationHint:
    'Document(s) intended for primary review (in lieu of DICOM images); e.g. medical clearance descriptions, implant/device documentation, etc.',
  documentsWillNotBeProvided: 'Documents will not be provided',
  documentsForInterpretationWillNotBeProvided:
    'Documents for interpretation will not be provided',
  techWorksheetWillNotBeProvided: 'Tech worksheet will not be provided',
  COMPARISON_REPORT: 'Comparison report',
  DICOM: 'Dicom',
  PRESCRIPTION: 'Prescription',
  HISTORY: 'History',
  DOCUMENT: 'Document',
  BILLING_INFORMATION: 'Billing information',
  TECH_WORKSHEET: 'Tech worksheet',
  MEDICAL_CLEARANCE_PROCESS: 'Documents for Interpretation',
  REPORT_APPENDIX: 'Report appendix',
  OTHER: 'Other',
  SITE_LOGO: 'Site logo',
  RADIOLOGIST_SIGNATURE: 'Radiologist signature',
  FAX: 'Fax',
  LEGAL_COPY: 'Legal copy',
  CSV_REPORT: 'CSV Report',
  timeSpent: 'Time Spent',
  timeSpentComment: 'Time Spent Comment',
  clearanceDesignation: 'Clearance designation',
  protocolSafety: 'Protocol Safety',
  NONE: '0 Minutes',
  UP_TO_15_MIN: '<15 Minutes',
  BETWEEN_16_AND_45_MIN: '16 - 45 Minutes',
  BETWEEN_46_AND_75_MIN: '46 - 75 Minutes',
  OVER_75_MIN: '>75 Minutes',

  TECH_ONLY: 'Tech Only',
  TECH_RESEARCH: 'Tech and Physician Review',
  PHYSICIAN_REVIEW: 'Physician Review',

  PROTOCOL: 'Protocol/Magnet Customization',
  DEVICE: 'Device / Implant Preparation',
  PATIENT: 'Patient / Device Respositioning',

  // Contrast info
  contrastInfoTitle: 'Contrast Info',
  addNDCButton: '+ Add NDC Code',
  ccMLLabel: 'CC/mL',
  numberTypeErrorMessage: 'must be a number',

  // Fluoro time
  fluoroTimeTitle: 'Fluoro time',
  fluoroTimeLabel: 'Fluoro time (min)',
  fluoroTimeValidationError: '${label} must be less than or equal to 100',

  notValid: '${label} is not valid',

  // Radiation dose
  radiationDoseLabel: 'Radiation dose',
  mSvLabel: 'mSv',
  DLPLabel: 'DLP',

  //threeDModifier
  threeDModifierLabel: '3D Modifier',
  NotPerformedLabel: 'Not performed',
  PerformedOnIndependentWorkstationLabel:
    'Performed on independent workstation',
  PerformedOnModalityWorkstation: 'Performed on modality workstation',

  //CADModifier
  CADModifierLabel: 'CAD Modifier',
  yesLabel: 'Yes',
  noLabel: 'No',

  //Primary studies
  primaryStudyUIDReceived: 'Study UID Code is received ({{studyUid}})',
  primaryRemoveUID: 'Remove UID',
  primaryStudiesSite: 'Site',
  primaryBillingType: 'Primary Billing type',
  primaryStudiesNotContainsDicom: 'Order does not contain DICOM study',
  noImagesUploaded: 'No Images uploaded',
  primaryStudiesNonDicomStudies: 'Non DICOM Studies',
  primaryStudiesHeader: 'Primary Studies',
  primaryStudiesBlockHint:
    'DICOM images are necessary to submit order. If you don\'t have any please click the checkbox "Orders does not contain DICOM study" and upload documents for interpretation in the Exam details tab',
  primaryStudiesAddExistingStudy: 'Add existing study ',
  primaryOpenInPax: 'Open Studies in PACS',
  primaryStudiesAddExistingStudyTooltip:
    'Please, uncheck "order does not contain DICOM study" to unblock this option',
  imagesDisplayError:
    'Warning: The images could not be displayed. Contact system support for assistance',
  uploadDicomErrorMessage:
    'Please upload ${label} files before saving or submission RIS-Order',

  //DICOMStudySeriesModal
  DICOMStudySeriesModalHeading: 'DICOM Study Series Information',
  noAvailableDocumentsYet: 'No available documents yet',
  imagesCountCaption_one: '{{count}} image',
  imagesCountCaption_other: '{{count}} images',

  //searchStudiesModal
  searchStudiesTitle: 'Search for studies',
  searchStudiesFilterCreationDateFrom: 'Creation date. From:',
  searchStudiesFilterCreationDateTo: 'Creation date. To:',
  searchStudiesFilterPatientName: 'Patient name',
  searchStudiesFilterModality: 'Modality',
  searchStudiesFilterStudyDescription: 'Study description',
  searchStudiesFilterStudyDateFrom: 'Study date. From:',
  searchStudiesFilterStudyDateTo: 'Study date. To:',
  searchStudiesSearchResultsTitle_one: '{{count}} study found',
  searchStudiesSearchResultsTitle_other: '{{count}} studies found',
  searchStudiesCreationDateColumnTitle: 'Creation date',
  searchStudiesPatientNameColumnTitle: 'Patient name',
  searchStudiesModalityColumnTitle: 'Modality',
  searchStudiesStudyDescriptionColumnTitle: 'Study description',
  searchStudiesStudyDateColumnTitle: 'Study date',

  //patientsInfo
  patientInfo: 'Patient info',
  patientInfoHeader: 'Patient Information',
  patientInfoRISOrderBlockHeader: 'Patient Information *',
  hlSeven: 'HL7',

  //comparisonInformation
  comparisonInfo: 'Comparison info',
  comparisonHeader: 'Comparison information',
  comparisonReports: 'Comparison reports',
  comparisonImagesTitle: 'Comparison images',
  comparisonImagesSourceOrder:
    'Comparison images were copied from the source order and are already being attached to the current order',
  comparisonImagesRequiredError:
    'This field is required. Please, upload necessary comparison images or mark it as "will not be provided"',
  paperworkRequiredError:
    'This field is required. Please upload necessary paperworks or mark it as “will not be provided” ',
  uploadDocumentsForInterpretationOrPrimaryStudyError:
    'Please upload Documents for Interpretation or/and Primary study',
  uploadPrimaryStudyOrDocumentsForInterpretationError:
    'Please upload Primary study or/and Documents for Interpretation on the Exam details tab',
  comparisonReportsRequiredError:
    'This field is required. Please, upload necessary comparison reports or mark it as "will not be provided"',
  comparisonReportsWillNotBeProvided: 'Comparison reports will not be provided',
  comparisonImagesWillNotBeProvided: 'Comparison images will not be provided',
  comparisonTitleDescription:
    'Upload or drop a document in {{formats}}. Max size 100 MB',
  comparisonUnloadedFilesWarningModalTitle:
    'You did not upload any {{filesType}}',
  comparisonUnloadedFilesWarningModalDescription:
    'Select the one option below or upload {{filesType}}',
  comparisonModalReportsDontExistRadioLabel:
    'Comparison {{filesType}} don’t exist',
  comparisonModalWaitingReportsRadioLabel:
    'Mark as Waiting for {{filesType}} and proceed ',
  comparisonWaitingForImages: 'Mark as Waiting for images',
  comparisonImagesDontExist: 'Images don’t exist',
  comparisonFilesErrorMessage: 'Select one option or upload files',

  //imagesTable
  creationDate: 'Creation date',
  patientName: 'Patient name',
  modality: 'Modality',
  studyDescription: 'Study description',
  studyDate: 'Study date',
  preview: 'Preview',
  content: 'Content',
  studyContent: '{{imgCount}} img in {{seriesCount}} series',

  //comparisonImagesSearch
  blockTitle: 'Search for images',
  companyNameTitle: 'Company Name',
  companyStudiesCounter_one: '({{count}} study)',
  companyStudiesCounter_other: '({{count}} studies)',
  searchCreationDateColumnTitle: 'Creation date',
  searchPatientNameColumnTitle: 'Patient name',
  modalityColumnTitle: 'Modality',
  studyDescriptionColumnTitle: 'Study description',
  studyDateColumnTitle: 'Study date',
  studiesSelected_one: '{{count}} study selected',
  studiesSelected_other: '{{count}} studies selected',
  selectedStudiesAdded:
    'Selected studies have been added to the list successfully.',
  creationDateFrom: 'Creation date. From:',
  creationDateTo: 'Creation date. To:',
  searchPatientName: 'Patient name',
  searchModality: 'Modality',
  searchStudyDescription: 'Study description',
  studyDateFrom: 'Study date. From:',
  studyDateTo: 'Study date. To:',

  // order-creation-notifications
  successfullySubmittedAndMovedToWaitingForAssignment:
    'RIS-Order has been successfully submitted and moved to “Waiting for assignment”',

  // copy order
  copyData: 'Copy data',
  patientId: 'Patient ID',
  copyDataStep: 'Step {{step}} of 2',
  copyDataDescription:
    'Choose an order you would like to copy the information to.',
  copyInformationStepDescription:
    'Manage the information you want to be copied to the target RIS-Orders',
  searchLabel: 'Search RIS-Order by Patient Name...',
  copyOrdersWithinLastDay: 'Show orders created within the last 24 hours',
  stepBack: 'Step Back',
  stepBackDescription:
    'Are you sure you want to leave this page and go to the previous one? The changes will not be saved.',
  cancelModalDescription:
    'Are you sure you want to leave this page? The changes will not be saved.',
  successCopy: 'Information has been successfully copied.',
  targetOrderEditedBy: 'Target orders are being edited by:',

  // assignment buttons
  assignButton: 'Assign',
  reassignButton: 'Reassign',
  removeAssignmentButton: 'Remove assignment',

  //assignment
  orders: 'Orders',
  previouslyAssignedRadiologists: 'See previously assigned radiologists',
  previouslyAssignedRadiologistsTitle: 'Previously assigned radiologists',
  previouslyAssignedRadiologistsDescription:
    'The previous orders of this patient (<patientLink>{{patientName}}</patientLink>) were assigned to:',
  contributingReadersTitle: 'Contributing Readers',
  lockedOrders:
    'This Order is locked by another user  in the assignment process',
  assignOrder_one: 'Assign RIS-Order',
  assignOrder_other: 'Assign RIS-Orders',
  multipleStudyAssignOrders:
    '<spanWrapper>Alert: The orders</spanWrapper> <orders></orders> <spanWrapper>were labeled as part of a multiple study. Additional procedures may be in progress. Please consider this before assigning.</spanWrapper>',
  risOrdersFlag: 'Ris-Orders: ',
  fromDontAssignment: 'Include Radiologists from Do Not Assign Panel',
  radiologistsTabTitle: 'Radiologists',
  groupsTabTitle: 'Groups',
  siteColumn: 'Site',
  radiologistColumn: 'Radiologist',
  prefPanelColumn: 'Pref. panel',
  prefPanelTooltip: 'Preferred Assignment Panel',
  privilegeCredentialColumn: 'Cred.',
  privilegeCredentialTooltip: 'Privilege Credentialing Panel',
  licenseColumn: 'License',
  licenseTooltip:
    'The match of the "State" of "Images acquired in" and "State" of the Medical licence of a radiologist',
  subSpecialtyColumn: 'Sub-specialty',
  subSpecialtyTooltip:
    'The match of radiologist sub-specialty and procedure sub-specialty of the order',
  rvuLeftColumn: 'RVU Left',
  rvuTargetColumn: 'RVU Target',
  rvuForTomorrow: 'Tmr',
  nonVisibleNotIncluded:
    '“Non-Visible” Radiologists, Radiologists whose procedure doesn’t match RIS-Order procedure are not included in the list',
  sourceAccessionColumn: 'Source Accession',
  assignmentInProcess: 'RIS-Order is in assignment process',
  unreadColumn: 'Unread',
  unreadForToday: 'Unread for today ({{count}})',
  unreadForTomorrow: 'Unread for tomorrow ({{count}})',
  urgency: 'Urgency',
  deliveryReportsFaxNumber: 'Deliver Reports to additional fax number',
  faxNumber: 'Fax Number',
  location: 'Location',
  HL7_TA_NAME: 'Transfer agent',
  FAX_PHONE_NUMBER: 'Fax Number',
  FAX_PHONE_NUMBER_LOCATION: 'Location',
  FAX_PROVIDER_DISPLAY_NAME: 'Provider',
  taName: 'Transfer agent',
  assign: 'Assign',
  tmrOrders_one: 'Tmr: {{count}} RIS-Order',
  tmrOrders_other: 'Tmr: {{count}} RIS-Orders',
  assignForTomorrow: 'Assign for tomorrow',

  // assignment-dialog
  linkedOrderReassign:
    'This order is linked with another one for the same patient. \nPlease review the linked orders:',
  ordersInAssignmentTitle: 'RIS-Orders in assignment right now:',
  lockedOrder: 'This Order is locked by another user in the assignment process',
  submitTime: 'Submit time',
  hasLockedOrders:
    'The above RIS-Order(s) are locked in assignment currently by another user and cannot be assigned.',
  assignAvailableOrders: 'Would you like to assign orders',
  // assignment-removing-dialog
  hasLockedOrdersCantRemove:
    'The above RIS-Order(s) are locked in assignment currently by another user, assignment can not be removed.',
  removeAvailableOrders: 'Would you like to remove assignment from orders',

  // assignment-notifications
  assignSuccess:
    'RIS-Order has been successfully assigned and moved to “Pending Review”',
  assignForTomorrowSuccess:
    'RIS-Order has been successfully assigned and moved to “Assigned - Waiting”',
  removeAssignSuccess:
    'RIS-Order has been successfully removed from assignment and moved to “Waiting for assignment”',
  reassignSuccess: 'RIS-Order has been successfully reassigned',
  lackOfActivityOnAssignment:
    'We are returning the Order back to the Assignment Pool due to lack of activity',

  //billingInformation
  billingInfoHeader: 'Billing for RIS-Order {{accessionNumber}}',
  billingSuccessNotification:
    'Billing has been successfully completed and moved to "{{status}}" status',

  //billing preview
  billingPreviewBreadcrumb: 'Billing Preview',
  billingInfo: 'Billing info',
  billingPreviewHeader: 'Billing for RIS-Order {{accessionNumber}}',

  // billing edit
  billingEditHeader: 'Edit Billing for RIS-Order {{accessionNumber}}',
  billingEditBreadcrumb: 'Edit Billing',

  // Add Provider from preview page
  addProvider: 'Add provider',

  // billing patient information tab
  billingPatientInformation: 'Patient information',
  billingInfoBlockHeader: 'Billing info',
  patientInfoBlockHeader: 'Patient info',
  billingTypeLabel: 'Billing type',
  guarantorsInfoBlockHeader: "Guarantor's info",
  guarantorsPhoneNumbersHeader: "Guarantor's Phone Numbers",
  policyHolderPhoneNumbersHeader: "Policy Holder's Phone Numbers *",
  guarantorsPhoneNumbersAddButtonLabel: 'Add Phone Number',

  // billing primary insurance tab
  billingPrimaryInsurance: 'Primary Insurance',
  billingInsurance: 'Insurance',
  billingInsuranceName: 'Insurance name',
  policyNumber: 'Policy number',
  groupNumber: 'Group number',
  dateOfInjury: 'Date of injury',
  claimNumber: 'Claim Number',
  injuryDetails: 'Injury Details',
  details: 'Details',
  authorizations: 'Authorizations',
  authorization1: 'Authorization 1',
  authorization2: 'Authorization 2',
  billingPolicyHolder: 'Policy Holder',
  billingPrimaryPolicyHolder:
    "Policy Holder's Information for Primary Insurance",
  billingSecondaryPolicyHolder:
    "Policy Holder's Information for Secondary Insurance",
  employerInfo: 'Employer Info',
  employerName: 'Employer name',
  employerPhoneNumber: 'Employer Phone Number',
  adjusterName: 'Adjuster name',
  adjusterPhoneNumber: 'Adjuster Phone Number',
  adjusterEmail: 'Adjuster email',

  // billing secondary insurance tab
  billingSecondaryInsurance: 'Secondary insurance',
  billingAddInsuranceTooltip:
    'Remove current insurance to search or create a new one',
  billingTable: {
    policyNumber: 'Policy Number',
    insuranceName: 'Name',
    address: 'Address',
    city: 'City',
    postalCode: 'Postal Code',
    payerId: 'Payer ID',
  },
  secondaryInsuranceDoesntExists: 'Patient doesn’t have secondary insurance',

  // billing revert
  revertBillingTitle:
    'Revert Billing Verification RIS-Order {{accessionNumber}}',
  revertDetailsLabel: 'Revert Details',
  reasonsLabel: 'Reasons',
  revertComponentLabel: 'Revert Component',
  instantRevertLabel: 'Instant revert',
  setUpDateLabel: 'Set up Date',
  revertDateLabel: 'Revert Date',
  pendingRevertOn: 'Pending Revert on',
  revertedOn: 'Reverted on',
  systemTags: 'System Tags',
  systemTagsCount: 'System Tags ({{tagsCount}})',
  markAsAcknowledged: 'Mark as “Acknowledged”',
  // report delivery results
  deliveryType: 'Delivery type',
  versionOfTheReport: 'Version of the Report',
  deliveryStatuses: {
    FAILED_ATTEMPT: 'Failed attempt',
    PENDING_DELIVERY: 'Pending',
    SUCCESSFULLY_SENT: 'Successfully sent',
    ACKNOWLEDGED: 'Acknowledged',
  },
  dateOfDelivery: 'Date of Delivery',
  addressOfDelivery: 'Address of Delivery',
  noImagesDelivered: 'No images match the forwarding criteria',
  imagesDelivered: 'Number of images: {{count}}',

  //report
  reportHeader: 'Report for RIS-Order {{accessionNumber}}',
  reportBreadcrumb: 'Report',
  finalReReviewReason: 'Final Review Reason',
  pendingReReviewReason: 'Pending Re-review Reason',
  rad: 'Rad',
  orderMovedTo: 'Order moved to {{ orderStatus }}',
  approvedAt: 'Approved',
  approver: 'Approver',
  approvalRuleReason: 'Approval Rule Reason',
  notApproved: 'Not approved',
  reportFor: 'Report for {{ reportName }}',
  modalHeading: 'Report Versions',
  legalCopies: 'Legal Copies',
  compareReports: 'Compare Reports',
  mergedView: 'Merged View',
  selectVersionIssue: 'Please select correct versions to compare',
  compare: 'Compare',
  version: 'Version',
  radiologist: 'Radiologist',
  versionNumber: 'Version Number',
  approvalTime: 'Approval Time',
  formedAt: 'Formed Time',
  menuEditReport: 'Edit Report',
  enablePreliminaryReport: 'Enable Preliminary Report',
  enablePreliminaryReportDescription:
    'Radiologist {{radiologist}} wants the preliminary reports to be public.',
  enablePreliminaryReportNotes: 'Notes',
  menuDownloadPDF: 'Download PDF',
  menuDownloadHTML: 'Download HTML',
  downloadHint: 'Report is being generated now. Please wait.',
  menuViewVersions: 'View Versions',
  menuViewLegalCopies: 'View Legal Copies',
  sendFax: 'Send Fax',
  sendFaxManually: 'Send Fax Manually',
  allVersions: 'All versions',
  reportDeliveryToReferringProvider: 'Report Delivery to Referring Provider',
  noReportsYet: 'No Reports Yet',
  createVersion: 'Create Version',
  approvedLabel: 'Approved',
  approverLabel: 'Approver',
  approvalDateTimeLabel: 'Approved Date and Time (EST)',
  primaryReviewerLabel: 'Primary Reviewer',
  attendingReviewerLabel: 'Attending Reviewer',
  transcriptionistLabel: 'Transcriptionist',
  transcriptionDateTimeLabel: 'Transcription Date and Time (EST)',
  reportPlaceholder: 'Report',

  //deliver
  deliverModalTitle: 'Deliver',
  acknowledgeReportDeliveryToSite: 'Acknowledge Report Delivery to Site',
  acknowledgeReportDeliveryToRefMD: 'Acknowledge Report Delivery to RefMD',

  // Tags
  tags: 'Tags',
  customerTags: 'Customer Tags ({{tagsCount}})',
  adminTags: 'Admin Tags ({{tagsCount}})',
  adminTagsTitle: 'Admin tags',
  customerTagsTitle: 'Customer tags',
  addTag: 'Add Tag',

  // Support Requests
  supportRequestsHeader: 'Support Requests ({{count}} Open)',
  type: 'Type',
  status: 'Status',
  lastActivity: 'Last Activity',
  sort: 'Sort: ',
  newestToOldest: 'Newest to oldest',
  oldestToNewest: 'Oldest to newest',

  // Order preview page
  noReport: 'No report',
  actions: 'Actions',
  radApproval: 'Rad approval',
  radReReview: 'Rad Re-review',
  redoDictation: 'Redo Dictation',
  complete: 'Complete',
  cancelationReason: 'Cancelation Reason',
  cancelRisOrder: 'Cancel RIS-Order {{accessionNumber}}',
  requestAddendum: 'Request addendum',
  addendumReason: 'Addendum reason',
  initiateAddendum: 'Initiate Addendum',
  unHold: 'Unhold',
  removeOrderFromOnHold: 'Remove RIS-Order {{accessionNumber}} from On Hold',
  removeOrderFromOnHoldConfirmationQuestion:
    'Are you sure you want to remove RIS-Order {{accessionNumber}} from On Hold?',
  reopenRisOrder: 'Reopen RIS-Order {{accessionNumber}}',
  removeProvider: 'Remove Provider',
  makeOrderingProvider: 'Make the Ordering Provider',
  reopenRisOrderConfirmationQuestion:
    'Are you sure you want to reopen RIS-Order {{accessionNumber}}?',
  toIndividual: 'To Individual',
  toReferringProvider: 'To Referring Provider',
  viaTransferAgent: 'Via Transfer Agent',
  agreeToAbide: 'I agree to abide by all HIPAA regulations related to PHI.',
  pleaseAgreeToAbide:
    'Please Agree to abide by all HIPAA regulations related to PHI.',
  reportDeliveryResults: 'Report Delivery Results',

  // Preview notifications
  successfulShare: 'RIS-Order {{accessionNumber}} has been successfully shared',
  failedShare: 'RIS-Order {{accessionNumber}} sharing failed. Please try again',
  isoIsBeingGenerated: 'ISO file is being generated now. Please wait.',
  legalCopyIsBeingGenerated:
    'Legal copy file is being generated now. Please wait.',
  transferInProgress:
    'Transfer in progress. Please wait for the process to complete',
  pushStudiesInPACSSuccessful: 'Studies are pushed to PACS',
  createManualReportVersionSuccessful: 'Manual report was created successfully',
  resendHL7Successful: 'Message was sent',

  insurancePlan: 'Insurance plan',
  requestInsurancePlan: 'Request Insurance plan',
  requestInsurancePlanSubmitted:
    'Your request for the new insurance plan has been submitted',
  requestInsurancePlanFailed:
    'Your request for the new insurance plan has not been submitted. Please try again letter',
  primaryInsuranceHistory: 'Primary Insurance History',
  secondaryInsuranceHistory: 'Secondary Insurance History',
  otherInsurance: 'Other insurance',
  transferAgent: 'Transfer Agent',
  dicomTags: 'DICOM Tags',
  dicomPatientName: '(0010,0010)/Patient Name',
  dicomGender: '(0010,0040)/Gender',
  dicomPatientBirthDate: '(0010,0030)/Patient Birth Date',
  dicomMrn: '(0010,0020)/MRN',
  dicomAccessionNumber: '(0008,0050)/Accession Number',
  dicomStudyDescription: '(0008,1030)/Study Description',
  agreeToSharePersonalInfo: 'Agree to share Personal Health Information (PHI)',
  pleaseAgreeToSharePHI:
    'Please Agree to share Personal Health Information (PHI)',
  missingInfo: 'Missing info',
  fileAttachment: 'File Attachment',
  willNotBeProvided: 'Will not be provided',

  // send fax
  otherFaxes: 'Other faxes',
  manageFaxes: 'Manage Faxes',
  siteFaxes: 'Site Faxes',
  manualFaxes: 'Manual Faxes',
  autoReportFaxDestinations: 'Auto-Report Fax Destinations',
  faxNumberAutoDelivery: 'Fax number  included into auto-delivery',
  numberOfAutoReportFaxDestinations_one:
    '{{count}} Auto-Report Fax Destination',
  numberOfAutoReportFaxDestinations_other:
    '{{count}} Auto-Report Fax Destinations',

  // audit
  audit: 'Audit',
  auditPatient: 'Patient Audit',
  auditHeader: 'Audit of RIS-Order {{accessionNumber}}',
  auditSearchPlaceholder: 'Search by User Name, Old Value or New Value',
  categoryColumn: 'Category',
  triggerColumn: 'Trigger',
  eventColumn: 'Event',
  timeColumn: 'Time',
  newValueColumn: 'New Value',
  oldValueColumn: 'Old Value',
  actionByColumn: 'Action by',
  eventDescription: 'Event Description',
  dateTimeFilterLibel: 'Date/Time',
  auditDetailsTitle: 'Audit Details',
  messageTitle: 'Message',
  transportEndpointTitle: 'Transport endpoint',
  deliveryDestinationsTitle: 'Delivery destinations',
  errorDescriptionTitle: 'Error description',
  messageIsOffline: 'The message is offline',

  // file-upload page
  addAttachments: 'Add Attachments',
  uploadFiles: 'Upload files',
  comparisonInformation: 'Comparison information',

  // personal info share agreement
  header: 'By selecting ‘I Agree,” you:',
  pointOne:
    'Agree that you are bound by and are responsible for complying with all applicable laws, including without limitation, the Health Insurance Portability and Accountability Act of 1996 (<strong>“HIPAA”</strong>), and ProScan’s <privacyPolicyLink>Privacy policy</privacyPolicyLink> and <termsOfServiceLink>Terms of Service</termsOfServiceLink>.',
  pointTwo:
    'Represent and warrant that you have obtained the necessary consents and authority to access and use protected health information (as such terms are defined in HIPAA) (<strong>“PHI”</strong>) and permit other users of the services and ProScan to access and use the foregoing PHI in accordance with ProScan’s <privacyPolicyLink>Privacy policy</privacyPolicyLink> and <termsOfServiceLink>Terms of Service</termsOfServiceLink>.',
  pointTree:
    'Will, upon the reasonable request of ProScan, make any documents or materials available to ProScan to prove the foregoing compliance, authority, and consent.',

  // Manual Report Delivery
  manualReportDelivery: 'Manual Report Delivery',
  dicomForwarding: 'DICOM forwarding',
  dicomForwardingError:
    'Select at least one DICOM rule for fax delivery report',
  fileName: 'File Name',
  recipientOfTheMessage: 'Recipient of the Message',
  retryAutoDelivery: 'Re-try Auto-delivery',
  useTransferAgent: 'Use Transfer Agent for Delivery to Client',

  period: 'Period',
  today: 'Today',
  last30Days: 'Last 30 days',
  last90Days: 'Last 90 days',
  thisWeek: 'This week',
  custom: 'Custom',
}
