import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATE } from 'types/general'

import { initialState } from './constants'
import * as controllers from './controllers'

export const name = 'tags'

const tagsSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetCreateTag: (state) => {
      state.createTag = initialState.createTag
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(controllers.fetchTagArchive.pending, (state) => {
        state.tagsTable.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.fetchTagArchive.fulfilled, (state) => {
        state.tagsTable.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(controllers.fetchTagArchive.rejected, (state, action) => {
        state.tagsTable.loadingState = LOADING_STATE.rejected
        state.tagsTable.error = action.payload
      })
      .addCase(controllers.fetchTags.pending, (state) => {
        state.tagsTable.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.fetchTags.fulfilled, (state, action) => {
        state.tagsTable.list = action.payload
        state.tagsTable.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(controllers.fetchTags.rejected, (state, action) => {
        state.tagsTable.loadingState = LOADING_STATE.rejected
        state.tagsTable.error = action.payload
      })
      .addCase(controllers.fetchExistingTagsName.pending, (state) => {
        state.createTag.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.fetchExistingTagsName.fulfilled, (state, action) => {
        state.createTag.list = action.payload
        state.createTag.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(controllers.fetchExistingTagsName.rejected, (state) => {
        state.createTag.loadingState = LOADING_STATE.rejected
        state.createTag.list = []
      })
  },
})

export const { resetCreateTag } = tagsSlice.actions

export default tagsSlice
