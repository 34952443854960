import { styled, Typography } from '@mui/material'

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isPaddingsClear',
})<{ isPaddingsClear?: boolean }>(
  ({ theme: { palette }, isPaddingsClear }) => ({
    fontSize: '12px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.4px',
    color: palette.error.main,
    ...(!isPaddingsClear && {
      paddingLeft: '12px',
      paddingTop: '4px',
    }),
  })
)
