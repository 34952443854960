import { createAsyncThunk } from '@reduxjs/toolkit'
import { SITE_AND_SUPER_USER_ROLES, USER_ROLES } from 'constants/userRoles'
import { TCompanyResponse } from 'store/company/types'
import { TUserInfoRs } from 'store/users/types'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'
import { prepareArrayForSending } from 'utils/helpers/arrays/prepareArrayForSending'

import { AUTH_SLICE_NAME } from './constants'
import {
  TSetFirebaseTokenRequest,
  TUpdateUserPasswordRq,
  TUserAssociatedCompanies,
} from './types'

interface IUserInfo {
  userInfo: TUserInfoRs
  associatedCompanies: TUserAssociatedCompanies
}

export const fetchUserInfo = createAsyncThunk<
  IUserInfo,
  void,
  { rejectValue: TServiceError }
>(`${AUTH_SLICE_NAME}/fetchUserInfo`, async (_, { rejectWithValue }) => {
  const result = await request<TUserInfoRs>({
    url: '/users/info',
    method: 'GET',
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  const userInfo = result.data
  const siteIds = userInfo?.siteIds ?? []

  const data: IUserInfo = {
    userInfo,
    associatedCompanies: {
      companies: [],
      totalCompanies: 0,
    },
  }

  if (
    SITE_AND_SUPER_USER_ROLES.find((role) =>
      (userInfo?.roles as Array<USER_ROLES>)?.includes(role)
    )
  ) {
    const associatedSitesResponse = await request<TCompanyResponse>({
      url: '/org/companies/sites',
      params: {
        isPaged: true,
        size: 1,
        siteIds: prepareArrayForSending(siteIds) ?? [],
      },
      method: 'GET',
    })

    if (associatedSitesResponse.data) {
      data.associatedCompanies = {
        companies: associatedSitesResponse.data.content ?? [],
        totalCompanies: associatedSitesResponse.data.totalElements ?? 0,
      }
    }
  }

  return data
})

export const updateLastLogin = createAsyncThunk<
  unknown,
  void,
  { rejectValue: TServiceError }
>(`${AUTH_SLICE_NAME}/updateLastLogin`, async (_, { rejectWithValue }) => {
  const result = await request<void>({
    url: '/users/lastLogin',
    method: 'POST',
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const setFirebaseNotificationTokenRq = createAsyncThunk<
  unknown,
  TSetFirebaseTokenRequest,
  { rejectValue: TServiceError }
>(
  `${AUTH_SLICE_NAME}/setFirebaseNotificationTokenRq`,
  async ({ firebaseToken }, { rejectWithValue }) => {
    const result = await request<void>({
      url: '/notification/firebase',
      method: 'PUT',
      data: {
        firebaseToken,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const updateUserPasswordRq = createAsyncThunk<
  unknown,
  TUpdateUserPasswordRq,
  { rejectValue: TServiceError }
>(
  `${AUTH_SLICE_NAME}/updateUserPasswordRq`,
  async (data, { rejectWithValue }) => {
    const result = await request<void>({
      url: '/users/update/password',
      data,
      method: 'PATCH',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)
