import { TOrder } from 'store/ordersList/types'
import { formatDateToUSLocale } from 'utils/helpers/date/formatDateToUSLocale'

export const getRisOrderOption = ({
  patient,
  accessionNumber,
  dateOfService,
  procedure,
}: TOrder) =>
  `${
    patient?.firstname && patient?.lastname
      ? `${patient?.firstname} ${patient?.lastname} | `
      : ''
  }${accessionNumber ? `${accessionNumber} |` : ''}${
    dateOfService ? ` ${formatDateToUSLocale(dateOfService)} |` : ''
  } ${procedure ?? ''}`
