import { FC } from 'react'

import { StyledTypography } from './PSErrorFieldMessage.styled'

export const PSErrorFieldMessage: FC<{
  text?: string
  isPaddingsClear?: boolean
}> = ({ text, isPaddingsClear = false }) =>
  text ? (
    <StyledTypography isPaddingsClear={isPaddingsClear}>
      {text}
    </StyledTypography>
  ) : null
