import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  MATCH_TYPE_ENUM,
  MULTIPLE_TYPE_ENUM,
  TCompareMultipleInfo,
} from 'store/patient/types'
import { getPatientCompareProscanMrn } from 'utils/helpers/patients/getPatientCompareProscanMrn'
import { LOADING_STATE } from 'types/general'

import { defaultOrderListState, initialState, SLICE_NAME } from './constants'
import * as controllers from './controllers'

const patientSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetPatientInfo: (state) => {
      state.patientInfo = initialState.patientInfo
    },
    resetPatientCompareInfo: (state) => {
      state.compareInfo = initialState.compareInfo
    },
    setTablePageable: (state, { payload }) => {
      if (state.compareInfo.ordersList[payload.patientId]) {
        state.compareInfo.ordersList[payload.patientId] = {
          ...state.compareInfo.ordersList[payload.patientId],
          pageable: {
            ...state.compareInfo.ordersList[payload.patientId]?.pageable,
            ...payload.pageable,
          },
        }
      } else {
        state.compareInfo.ordersList[payload.patientId] = {
          pageable: payload.pageable,
        }
      }
    },
    setPatientCompareMultipleInfo: (
      state,
      { payload }: PayloadAction<Partial<TCompareMultipleInfo>>
    ) => {
      state.compareInfo.multipleInfo = {
        ...state.compareInfo.multipleInfo,
        ...payload,
      }
    },
    switchCompareRecords: (state) => {
      const targetCompareInfo = state.compareInfo.info?.targetInfo
      state.compareInfo.info = {
        noMatchOnly: state.compareInfo.info?.noMatchOnly ?? false,
        targetInfo: state.compareInfo.info?.sourceInfo,
        sourceInfo: targetCompareInfo,
      }
      state.compareInfo.multipleInfo.duplicateIdsPosition =
        state.compareInfo.multipleInfo.duplicateIdsPosition ===
        MULTIPLE_TYPE_ENUM.TARGET
          ? MULTIPLE_TYPE_ENUM.SOURCE
          : MULTIPLE_TYPE_ENUM.TARGET
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(controllers.getPatientById.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.getPatientById.fulfilled, (state, { payload }) => {
        state.loadingState = LOADING_STATE.fulfilled
        state.patientInfo = {
          ...payload,
          sourceResources: [...payload.sourceResources].sort((a) =>
            a?.matchType === MATCH_TYPE_ENUM.AUTO_MATCH ? -1 : 1
          ),
        }
      })
      .addCase(controllers.getPatientById.rejected, (state, action) => {
        state.loadingState = LOADING_STATE.rejected
        state.patientInfo = initialState.patientInfo
        state.error = action.payload
      })
      .addCase(controllers.fetchDuplicateCompareInfo.pending, (state) => {
        state.compareInfo.loadingState = LOADING_STATE.pending
      })
      .addCase(
        controllers.fetchDuplicateCompareInfo.fulfilled,
        (
          state,
          {
            payload: { targetInfo, sourceInfo, noMatchOnly },
            meta: {
              arg: { shouldSwitchGoldenResources },
            },
          }
        ) => {
          state.compareInfo.loadingState = LOADING_STATE.fulfilled
          if (
            targetInfo?.patientInfo?.isGoldenResource &&
            sourceInfo?.patientInfo?.isGoldenResource &&
            shouldSwitchGoldenResources
          ) {
            const targetProscanMrn = getPatientCompareProscanMrn(targetInfo)
            const sourceProscanMrn = getPatientCompareProscanMrn(sourceInfo)
            const shouldNotSwitch = targetProscanMrn < sourceProscanMrn
            state.compareInfo.info = shouldNotSwitch
              ? { targetInfo, sourceInfo, noMatchOnly }
              : { sourceInfo: targetInfo, targetInfo: sourceInfo, noMatchOnly }
            if (!shouldNotSwitch) {
              state.compareInfo.multipleInfo.duplicateIdsPosition =
                MULTIPLE_TYPE_ENUM.SOURCE
            }
          } else {
            state.compareInfo.info = { targetInfo, sourceInfo, noMatchOnly }
          }
        }
      )
      .addCase(
        controllers.fetchDuplicateCompareInfo.rejected,
        (state, action) => {
          state.compareInfo.loadingState = LOADING_STATE.rejected
          state.compareInfo.info = initialState.compareInfo?.info
          state.error = action.payload
        }
      )
      .addCase(
        controllers.fetchAssociatedOrders.pending,
        (
          state,
          {
            meta: {
              arg: { patientId },
            },
          }
        ) => {
          state.compareInfo.ordersList = {
            ...state.compareInfo.ordersList,
            [patientId]: {
              ...state.compareInfo.ordersList?.[patientId],
              loadingState: LOADING_STATE.pending,
            },
          }
        }
      )
      .addCase(
        controllers.fetchAssociatedOrders.fulfilled,
        (
          state,
          {
            meta: {
              arg: { patientId },
            },
            payload,
          }
        ) => {
          if (state.compareInfo.ordersList?.[patientId]) {
            state.compareInfo.ordersList[patientId] = {
              ...state.compareInfo.ordersList[patientId],
              data: payload,
              loadingState: LOADING_STATE.fulfilled,
            }
          } else {
            state.compareInfo.ordersList = {
              ...state.compareInfo.ordersList,
              [patientId]: {
                data: payload,
                error: null,
                loadingState: LOADING_STATE.fulfilled,
                pageable: defaultOrderListState.pageable,
              },
            }
          }
        }
      )
      .addCase(
        controllers.fetchAssociatedOrders.rejected,
        (
          state,
          {
            meta: {
              arg: { patientId },
            },
            payload,
          }
        ) => {
          state.compareInfo.ordersList = {
            [patientId]: {
              ...state.compareInfo.ordersList?.[patientId],
              data: undefined,
              error: payload,
              loadingState: LOADING_STATE.rejected,
            },
          }
        }
      )
      .addCase(controllers.linkPatient.pending, (state) => {
        state.compareInfo.loadingState = LOADING_STATE.pending
      })
      .addCase(
        controllers.linkPatient.fulfilled,
        (
          state,
          {
            meta: {
              arg: { goldenResourceIdTo, resourceIdFrom },
            },
          }
        ) => {
          state.compareInfo.loadingState = LOADING_STATE.fulfilled
          state.compareInfo.multipleInfo.duplicateIds =
            state.compareInfo.multipleInfo.duplicateIds.filter((id) => {
              if (
                state.compareInfo.multipleInfo.duplicateIdsPosition ===
                MULTIPLE_TYPE_ENUM.TARGET
              ) {
                return id !== goldenResourceIdTo
              } else {
                return id !== resourceIdFrom
              }
            })
        }
      )
      .addCase(controllers.linkPatient.rejected, (state) => {
        state.compareInfo.loadingState = LOADING_STATE.rejected
      })
      .addCase(
        controllers.fetchPatientComparisonInfo.fulfilled,
        (state, { payload }) => {
          state.compareInfo.multipleInfo = {
            resourceId: payload.sourceResourceId,
            duplicateIds: payload.targetGoldenResourceIds,
            duplicateIdsPosition: MULTIPLE_TYPE_ENUM.TARGET,
          }
        }
      )
      .addCase(
        controllers.fetchPatientComparisonInfo.rejected,
        (state, action) => {
          state.error = action.payload
        }
      )
  },
})

export const {
  resetPatientInfo,
  resetPatientCompareInfo,
  setTablePageable,
  setPatientCompareMultipleInfo,
  switchCompareRecords,
} = patientSlice.actions

export default patientSlice
