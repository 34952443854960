import { TOrdersTableParams } from 'store/filters/orders/types'
import { prepareArrayForSending } from 'utils/helpers/arrays/prepareArrayForSending'

type GetArrayKeys<T> = {
  [K in keyof T]: T[K] extends Array<any> ? K : never
}[keyof T]

type GetSimpleKeys<T> = {
  [K in keyof T]: T[K] extends Array<any> ? never : K
}[keyof T]

type TArrayKeys = GetArrayKeys<Required<TOrdersTableParams>>
type TSimpleKeys = GetSimpleKeys<Required<TOrdersTableParams>>

const arrayKeys: TArrayKeys[] = [
  'urgencies',
  'siteIds',
  'companyIds',
  'tagIds',
  'systemTagIds',
  'holdReasons',
  'states',
  'modalityIds',
  'bodyPartIds',
  'procedureIds',
  'cptCodeIds',
  'radiologistIds',
  'contributorReaderIds',
  'physicianIds',
  'billingTypeIds',
  'billingStatuses',
  'reportQaReasons',
  'insuranceGroupNames',
  'orderStatuses',
  'patientIds',
  'deliveryStatuses',
]

const simpleKeys: TSimpleKeys[] = [
  'proscanMrnSearch',
  'patientSearch',
  'siteSearch',
  'proscanAccessionSearch',
  'sourceMrnSearch',
  'sourceAccessionSearch',
  'hideInReview',
  'providerId',
  'isInactiveStatuses',
  'dateOfServiceFrom',
  'dateOfServiceTo',
  'patientDayOfBirth',
  'followUpFrom',
  'followUpTo',
  'hasAssignedRadiologist',
  'orderStatus',
  'sort',
]

export const prepareTableQueryParamsForSend = (
  searchParams: TOrdersTableParams
) => {
  const result = arrayKeys.reduce<
    Record<string, string | undefined | boolean | object | number | null>
  >(
    (acc, key) => {
      if (Array.isArray(searchParams[key]) && searchParams[key].length) {
        acc[key] = prepareArrayForSending(searchParams[key])
      }
      return acc
    },
    {} as Record<string, string | undefined | boolean | number | null>
  )

  simpleKeys.forEach((key) => {
    if (searchParams[key] !== undefined) {
      result[key] = searchParams[key]
    }
  })

  return result
}
