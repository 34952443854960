import { IState } from 'store/tags/types'
import { LOADING_STATE } from 'types/general'

export const TAG_SIZE = 20

export const TAG_PAGE = 0

export const initialState: IState = {
  tagsTable: {
    list: {
      content: [],
      totalElements: 0,
      totalPages: 0,
      first: true,
      last: false,
    },
    loadingState: LOADING_STATE.idle,
    error: null,
  },
  createTag: {
    loadingState: LOADING_STATE.idle,
    list: [],
  },
}
