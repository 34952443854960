import { createAsyncThunk } from '@reduxjs/toolkit'
import { SEARCH_TYPE } from 'services/bff/types'
import { SLICE_NAME } from 'store/patient/constants'
import {
  LINK_UPDATE_ENUM,
  TBffOrdersComparisonPageRs,
  TBffPatientComparisonRs,
  TComparisonInfoResponse,
  TPatient,
  TPatientBatchUpdateRq,
  TPatientComparisonFilterRq,
  TPatientRequest,
  TPatientResponse,
  TPatientSearchRequest,
  TPatientSearchResponse,
} from 'store/patient/types'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'
import { TPageable } from 'types/general'

export const createPatient = createAsyncThunk<
  TPatientResponse,
  TPatientRequest,
  { rejectValue: TServiceError }
>(`${SLICE_NAME}/createPatient`, async (data, { rejectWithValue }) => {
  const result = await request<TPatientResponse>({
    url: '/bff/patients',
    data,
    method: 'POST',
  })

  if (result.data) {
    return result.data
  }

  return rejectWithValue(result.error)
})

export const updatePatient = createAsyncThunk<
  TPatientResponse,
  { id: string; data: TPatientRequest; accessionNumber?: string },
  { rejectValue: TServiceError }
>(
  `${SLICE_NAME}/updatePatient`,
  async ({ id, data, accessionNumber }, { rejectWithValue }) => {
    const result = await request<TPatientResponse>({
      url: `/bff/patients/${id}`,
      data,
      ...(accessionNumber
        ? {
            headers: {
              AccessionNumber: accessionNumber,
            },
          }
        : {}),
      method: 'PUT',
    })

    if (result.data) {
      return result.data
    }

    return rejectWithValue(result.error)
  }
)

export const getAllPatients = createAsyncThunk<
  TPatientSearchResponse,
  {
    abortSignal?: AbortSignal
    searchData: TPatientSearchRequest
  },
  { rejectValue: TServiceError }
>(
  `${SLICE_NAME}/getAllPatients`,
  async ({ searchData, abortSignal }, { rejectWithValue }) => {
    const result = await request<TPatientSearchResponse>({
      url: 'bff/orders/patients/grid',
      params: { ...searchData, searchType: SEARCH_TYPE.ORDER_VIEW },
      method: 'GET',
      signal: abortSignal,
    })

    if (result.data) {
      return result.data
    }

    return rejectWithValue(result.error)
  }
)

export const fetchDuplicateCompareInfo = createAsyncThunk<
  TBffPatientComparisonRs,
  TPatientComparisonFilterRq & { shouldSwitchGoldenResources?: boolean },
  { rejectValue: TServiceError }
>(
  `${SLICE_NAME}/fetchDuplicateCompareInfo`,
  async (
    {
      shouldSwitchGoldenResources: _shouldSwitchGoldenResources,
      ...restParams
    },
    { rejectWithValue }
  ) => {
    const result = await request<TBffPatientComparisonRs>({
      url: '/bff/patients/comparison',
      method: 'GET',
      params: restParams,
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const fetchAssociatedOrders = createAsyncThunk<
  TBffOrdersComparisonPageRs,
  { patientId: string; queryData: TPageable },
  { rejectValue: TServiceError }
>(
  `${SLICE_NAME}/fetchAssociatedOrders`,
  async ({ patientId, queryData }, { rejectWithValue }) => {
    const result = await request<TBffOrdersComparisonPageRs>({
      url: `bff/orders/patients/${patientId}`,
      method: 'GET',
      params: queryData,
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const getPatientById = createAsyncThunk<
  TPatient,
  string,
  { rejectValue: TServiceError }
>(`${SLICE_NAME}/getPatientById`, async (patientId, { rejectWithValue }) => {
  const result = await request<TPatient>({
    url: `/bff/patients/info/${patientId}`,
    method: 'GET',
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const linkPatient = createAsyncThunk<
  unknown,
  {
    goldenResourceIdTo: string
    resourceIdFrom: string
    linkUpdateType: LINK_UPDATE_ENUM
    comment?: string
  },
  { rejectValue: TServiceError }
>(`${SLICE_NAME}/linkPatient`, async (data, { rejectWithValue }) => {
  const result = await request({
    url: '/patients/patient-link',
    method: 'PUT',
    data,
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const fetchPatientComparisonInfo = createAsyncThunk<
  TComparisonInfoResponse,
  string,
  { rejectValue: TServiceError }
>(
  `${SLICE_NAME}/fetchPatientDuplicateInfo`,
  async (patientId, { rejectWithValue }) => {
    const result = await request<TComparisonInfoResponse>({
      url: `/patients/comparison/${patientId}`,
      method: 'GET',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const updatePatients = createAsyncThunk<
  unknown,
  TPatientBatchUpdateRq[],
  { rejectValue: TServiceError }
>(`${SLICE_NAME}/updatePatients`, async (data, { rejectWithValue }) => {
  const result = await request({
    url: '/bff/patients/batch',
    data,
    method: 'PUT',
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})
