import { subDay } from 'utils/helpers/date/subDay'
import { LOADING_STATE } from 'types/general'

import { IState, ORDER_STATUS_ENUM, TOrderResponse } from './types'

const theDateThirtyDaysBeforeToday = String(subDay(new Date(), 30))

export const ORDER_SLICE_NAME = 'order'

export const initialState: IState = {
  supportRequests: {
    list: {
      content: [],
      totalElements: 0,
      totalPages: 0,
      isFirstPage: true,
      isLastPage: false,
    },
    commentsData: {},
    count: 0,
    page: 0,
    loadingState: LOADING_STATE.idle,
  },
  reportDeliveryResults: {
    content: [],
    loadingState: LOADING_STATE.idle,
  },
  orderInfo: {
    loadingState: LOADING_STATE.idle,
    enhanceLinkLoadingState: LOADING_STATE.idle,
    data: null,
  },
  studiesSearch: {
    primaryStudiesTab: {
      queryData: {
        creationDateFrom: theDateThirtyDaysBeforeToday,
        creationDateTo: String(new Date()),
        patientName: '',
        sourceMrn: '',
        modality: '',
        studyDescription: '',
        studyDateFrom: null,
        studyDateTo: null,
        page: 0,
        size: 10,
        byCompany: false,
      },
      totalElements: 0,
      totalPages: 0,
      isFirstPage: true,
      isLastPage: false,
      totalStudies: 0,
      groupedContent: [],
      nonGroupedContent: [],
      isLoading: false,
    },
    comparisonInfoTab: {
      queryData: {
        creationDateFrom: theDateThirtyDaysBeforeToday,
        creationDateTo: String(new Date()),
        patientName: '',
        sourceMrn: '',
        modality: '',
        studyDescription: '',
        studyDateFrom: null,
        studyDateTo: null,
        page: 0,
        size: 10,
      },
      totalElements: 0,
      totalPages: 0,
      isFirstPage: true,
      isLastPage: false,
      totalStudies: 0,
      groupedContent: [],
      nonGroupedContent: [],
      isTableShown: false,
      isLoading: false,
      isLoadingCompanyStudies: false,
    },
  },
  inconsistentPatient: {
    data: {
      dicom: {
        sourceMrn: false,
        firstName: false,
        lastName: false,
        dateOfBirth: false,
        genderAtBirth: false,
      },
      hlSeven: {
        sourceMrn: false,
        firstName: false,
        lastName: false,
        dateOfBirth: false,
        genderAtBirth: false,
      },
    },
    source: null,
    isInconsistencyAcknowledgmentChecked: false,
    isModalShown: false,
  },
  pushStudiesToPACSProgress: {},
  resendHL7ToDownstreamProgress: {},
  manualReportDelivery: {
    loadingState: LOADING_STATE.idle,
    data: undefined,
  },
  copyData: {
    loadingState: LOADING_STATE.idle,
    pageable: {
      page: 0,
      size: 10,
    },
    selectedOrders: {},
    selectedOrdersEditInfo: {},
    selectedOrdersInfo: {},
  },
}

export const FINAL_ORDER_STATUSES: TOrderResponse['status'][] = [
  ORDER_STATUS_ENUM.CANCELED,
  ORDER_STATUS_ENUM.NO_REPORT,
  ORDER_STATUS_ENUM.FINAL_REPORT,
  ORDER_STATUS_ENUM.FINAL_REPORT_ADDENDUM,
  ORDER_STATUS_ENUM.REPORT_DELIVERED,
]

export const STATUSES_WITH_REASON: TOrderResponse['status'][] = [
  ORDER_STATUS_ENUM.CANCELED,
  ORDER_STATUS_ENUM.ON_HOLD,
  ORDER_STATUS_ENUM.REPORT_QA,
]
