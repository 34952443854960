import { createAsyncThunk } from '@reduxjs/toolkit'
import { DICOM_DESTINATION_BASE_URL } from 'constants/apiUrls'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'
import { TQueryData, TTablePageableQueryData } from 'types/general'

import { sliceName } from './constants'
import {
  TDestinationGroupCreateRs,
  TDestinationGroupRq,
  TDestinationGroupRs,
  TDestinationGroupsResponse,
  TDestinationGroupUpdateRs,
  TDestinationRq,
  TDestinationRs,
  TDestinationsResponse,
} from './types'

// INFO: modality-server same as destination
export const getDicomDestinationRq = createAsyncThunk<
  TDestinationRs,
  string,
  { rejectValue: TServiceError }
>(
  `${sliceName}/getDicomDestinationRq`,
  async (destinationId, { rejectWithValue }) => {
    const result = await request<TDestinationRs>({
      url: `${DICOM_DESTINATION_BASE_URL}/${destinationId}`,
      method: 'GET',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const createDestinationRq = createAsyncThunk<
  number,
  TDestinationRq,
  { rejectValue: TServiceError }
>(
  `${sliceName}/createDestinationRq`,
  async ({ ...destinationInfo }, { rejectWithValue }) => {
    const result = await request<number>({
      url: DICOM_DESTINATION_BASE_URL,
      data: destinationInfo,
      method: 'POST',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const updateDestinationRq = createAsyncThunk<
  number,
  TDestinationRq & { destinationId: string },
  { rejectValue: TServiceError }
>(
  `${sliceName}/updateDestinationRq`,
  async ({ destinationId, ...destinationInfo }, { rejectWithValue }) => {
    const result = await request<number>({
      url: `${DICOM_DESTINATION_BASE_URL}/${destinationId}`,
      data: destinationInfo,
      method: 'PUT',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const deleteDestinationRq = createAsyncThunk<
  unknown,
  string,
  { rejectValue: TServiceError }
>(
  `${sliceName}/deleteDestinationRq`,
  async (destinationId, { rejectWithValue }) => {
    const result = await request<void>({
      url: `${DICOM_DESTINATION_BASE_URL}/${destinationId}`,
      method: 'DELETE',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const getDestinations = createAsyncThunk<
  TDestinationsResponse,
  {
    params: Partial<TTablePageableQueryData>
    abortSignal?: AbortSignal
  },
  { rejectValue: TServiceError }
>(
  `${sliceName}/getDestinations`,
  async ({ params, abortSignal }, { rejectWithValue }) => {
    const result = await request<TDestinationsResponse>({
      url: '/transferagentconfig/destination/search',
      method: 'GET',
      params,
      signal: abortSignal,
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const getDestinationGroups = createAsyncThunk<
  TDestinationGroupsResponse,
  { params: TQueryData; abortSignal?: AbortSignal },
  { rejectValue: TServiceError }
>(
  'destinations/getDestinationGroups',
  async ({ params, abortSignal }, { rejectWithValue }) => {
    const result = await request<TDestinationGroupsResponse>({
      url: '/transferagentconfig/modality-groups/search',
      method: 'GET',
      params,
      signal: abortSignal,
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const getDicomDestinationGroupRq = createAsyncThunk<
  TDestinationGroupRs,
  string,
  { rejectValue: TServiceError }
>(
  `${sliceName}/getDicomDestinationGroupRq`,
  async (destinationId, { rejectWithValue }) => {
    const result = await request<TDestinationGroupRs>({
      url: `/transferagentconfig/modality-groups/${destinationId}`,
      method: 'GET',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const createDestinationGroupRq = createAsyncThunk<
  TDestinationGroupCreateRs,
  TDestinationGroupRq,
  { rejectValue: TServiceError }
>(
  `${sliceName}/createDestinationGroupRq`,
  async ({ ...data }, { rejectWithValue }) => {
    const result = await request<TDestinationGroupCreateRs>({
      url: '/transferagentconfig/modality-groups',
      data,
      method: 'POST',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const updateDestinationGroupRq = createAsyncThunk<
  TDestinationGroupUpdateRs,
  TDestinationGroupRq & { destinationGroupId: string },
  { rejectValue: TServiceError }
>(
  `${sliceName}/updateDestinationGroupRq`,
  async ({ destinationGroupId, ...data }, { rejectWithValue }) => {
    const result = await request<TDestinationGroupUpdateRs>({
      url: `/transferagentconfig/modality-groups/${destinationGroupId}`,
      data,
      method: 'PUT',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const deleteDestinationGroupRq = createAsyncThunk<
  unknown,
  string,
  { rejectValue: TServiceError }
>(
  `${sliceName}/deleteDestinationGroupRq`,
  async (destinationGroupId, { rejectWithValue }) => {
    const result = await request<void>({
      url: `/transferagentconfig/modality-groups/${destinationGroupId}`,
      method: 'DELETE',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)
